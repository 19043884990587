import { NgIf } from '@angular/common';
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormGroupDirective, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { create as createLadda } from 'ladda';
import {
  concat as observableConcat,
  interval as observableInterval,
  merge as observableMerge,
  of as observableOf,
  Subject,
  Subscription,
} from 'rxjs';
import { filter, map, scan, switchMap, take, takeUntil, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'button[ladda]',
  standalone: true,
  imports: [NgIf],
  template: `
    <span *ngIf="!hideLabel" class="label">
      <ng-content *ngIf="!label"> </ng-content>
      <ng-container *ngIf="label">{{ label }}</ng-container>
    </span>
  `,
})
export class LaddaComponent implements OnInit, OnDestroy {
  private loadingState$: Subject<boolean> = new Subject();
  private el: HTMLButtonElement;
  private button: any;

  private dataSubs = new Subscription();

  @HostBinding('class.ladda-button')
  public laddaButton = true;
  @HostBinding('attr.data-style')
  @Input()
  public laddaStyle = 'zoom-in';

  @Input()
  public label: string;

  @Input()
  public hideLabel: boolean;

  @Input()
  public set ladda(isLoading: boolean) {
    this.loadingState$.next(isLoading);
  }

  public constructor(
    private elementRef: ElementRef,
    @Optional() private form: FormGroupDirective,
  ) {}

  public ngOnInit(): void {
    this.el = this.elementRef.nativeElement;
    this.button = createLadda(this.el);

    this.startListening();

    if (this.form) {
      this.dataSubs.add(
        this.form.ngSubmit.subscribe(() => {
          this.markFormAsTouchedAndDirty(this.form.form);
        }),
      );
    }
  }

  private startListening() {
    this.dataSubs.add(this.getButtonProgressSubscription());
  }

  private getButtonProgressSubscription(): Subscription {
    const startLoading = this.loadingState$.pipe(filter((isLoading) => isLoading));

    const finishLoading = this.loadingState$.pipe(filter((isLoading) => !isLoading));

    const progress = observableConcat(
      observableOf('start'),
      observableInterval(250).pipe(
        scan((current_progress) => current_progress + this.incrementTimer(current_progress), 0),
        takeWhile((progress) => progress < 90),
        takeUntil(finishLoading),
      ),
    );

    return observableMerge(
      startLoading.pipe(switchMap(() => progress)),
      finishLoading.pipe(
        switchMap(() =>
          observableConcat(
            observableOf(99),
            observableInterval(150).pipe(
              map(() => 'stop'),
              take(1),
            ),
          ),
        ),
      ),
    ).subscribe((progress) => {
      if (progress === 'start') {
        this.button.stop();
        this.button.start();
        return;
      }
      if (progress === 'stop') {
        this.button.stop();
        return;
      }

      if (typeof progress !== 'number') {
        return;
      }

      this.button.setProgress(progress / 100);
    });
  }

  private incrementTimer(progress): number {
    if (progress < 25) {
      // Start out between 3 - 6% increments
      return Math.random() * (5 - 3 + 1) + 3;
    }

    if (progress < 65) {
      // increment between 0 - 3%
      return Math.random() * 3;
    }

    if (progress < 90) {
      // increment between 0 - 2%
      return Math.random() * 2;
    }

    //dont increment any further
    return 0;
  }

  private markFormAsTouchedAndDirty(form: AbstractControl) {
    if (form instanceof UntypedFormGroup) {
      this.markFormGroupAsTouched(form);
    }

    if (form instanceof UntypedFormArray) {
      this.markFormArrayAsTouched(form);
    }

    form.markAsDirty({ onlySelf: true });
    form.markAsTouched({ onlySelf: true });
    form.updateValueAndValidity({ onlySelf: true });
  }

  private markFormGroupAsTouched(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((controlName) =>
      this.markFormAsTouchedAndDirty(formGroup.controls[controlName]),
    );
  }

  private markFormArrayAsTouched(formArray: UntypedFormArray) {
    formArray.controls.forEach((control: AbstractControl) => this.markFormAsTouchedAndDirty(control));
  }

  public ngOnDestroy(): void {
    this.dataSubs.unsubscribe();
    if (this.button) this.button.remove();
  }
}
